import { ColumnConfig, ColumnDataType } from '@aurora';

export const messageColumnsConfig: ColumnConfig[] = [
    {
        type           : ColumnDataType.STRING,
        field          : 'group.name',
        searchableField: '$group.name$',
        sort           : 'group.name',
        translation    : 'Name',
        isUnaccent     : true,
    },
    {
        type           : ColumnDataType.STRING,
        field          : 'conversation.name',
        searchableField: '$conversation.name$',
        sort           : 'conversation.name',
        translation    : 'Name',
        isUnaccent     : true,
    },
    {
        type           : ColumnDataType.STRING,
        field          : 'account.name',
        searchableField: '$account.name$',
        sort           : 'account.name',
        translation    : 'Name',
        isUnaccent     : true,
    },
    {
        type       : ColumnDataType.ENUM,
        field      : 'status',
        sort       : 'status',
        translation: 'chat.Status',
    },
    {
        type       : ColumnDataType.STRING,
        field      : 'message',
        sort       : 'message',
        translation: 'chat.Message',
        isUnaccent : true,
    },
];
