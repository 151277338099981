import { createConversationAndPaginateMessageQuery } from './message.graphql';
import { Injectable } from '@angular/core';
import { DocumentNode, FetchResult } from '@apollo/client/core';
import { ChatCreateMessage, ChatUpdateMessageById, ChatUpdateMessages } from '@apps/chat';
import { createMutation, deleteByIdMutation, deleteMutation, fields, findByIdQuery, findQuery, getQuery, insertMutation, paginationQuery, updateByIdMutation, updateMutation } from '@apps/chat/message';
import { ChatMessage, GraphQLHeaders, GraphQLService, GridData, parseGqlFields, QueryStatement } from '@aurora';
import { BehaviorSubject, first, map, Observable, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessageService
{
    paginationSubject$: BehaviorSubject<GridData<ChatMessage> | null> = new BehaviorSubject(null);
    messageSubject$: BehaviorSubject<ChatMessage | null> = new BehaviorSubject(null);
    messagesSubject$: BehaviorSubject<ChatMessage[] | null> = new BehaviorSubject(null);

    // scoped subjects
    paginationScoped: { [key: string]: BehaviorSubject<GridData<ChatMessage> | null>; } = {};
    messageScoped: { [key: string]: BehaviorSubject<ChatMessage | null>; } = {};
    messagesScoped: { [key: string]: BehaviorSubject<ChatMessage[] | null>; } = {};

    constructor(
        private readonly graphqlService: GraphQLService,
    ) {}

    /**
    * Getters
    */
    get pagination$(): Observable<GridData<ChatMessage>>
    {
        return this.paginationSubject$.asObservable();
    }

    get message$(): Observable<ChatMessage>
    {
        return this.messageSubject$.asObservable();
    }

    get messages$(): Observable<ChatMessage[]>
    {
        return this.messagesSubject$.asObservable();
    }

    // allows to store different types of pagination under different scopes this allows us
    // to have multiple observables with different streams of pagination data.
    setScopePagination(scope: string, pagination: GridData<ChatMessage>): void
    {
        if (this.paginationScoped[scope])
        {
            this.paginationScoped[scope].next(pagination);
            return;
        }
        // create new subject if not exist
        this.paginationScoped[scope] = new BehaviorSubject(pagination);
    }

    // get pagination observable by scope
    getScopePagination(scope: string): Observable<GridData<ChatMessage>>
    {
        if (this.paginationScoped[scope]) return this.paginationScoped[scope].asObservable();

        this.paginationScoped[scope] = new BehaviorSubject(null);
        return this.paginationScoped[scope].asObservable();
    }

    setScopeMessage(scope: string, object: ChatMessage): void
    {
        if (this.messageScoped[scope])
        {
            this.messageScoped[scope].next(object);
            return;
        }
        // create new subject if not exist
        this.messageScoped[scope] = new BehaviorSubject(object);
    }

    getScopeMessage(scope: string): Observable<ChatMessage>
    {
        if (this.messageScoped[scope]) return this.messageScoped[scope].asObservable();

        this.messageScoped[scope] = new BehaviorSubject(null);
        return this.messageScoped[scope].asObservable();
    }

    setScopeMessages(scope: string, objects: ChatMessage[]): void
    {
        if (this.messagesScoped[scope])
        {
            this.messagesScoped[scope].next(objects);
            return;
        }
        // create new subject if not exist
        this.messagesScoped[scope] = new BehaviorSubject(objects);
    }

    getScopeMessages(scope: string): Observable<ChatMessage[]>
    {
        if (this.messagesScoped[scope]) return this.messagesScoped[scope].asObservable();

        this.messagesScoped[scope] = new BehaviorSubject(null);
        return this.messagesScoped[scope].asObservable();
    }

    pagination(
        {
            graphqlStatement = paginationQuery,
            query = {},
            constraint = {},
            headers = {},
            scope,
        }: {
            graphqlStatement?: DocumentNode;
            query?: QueryStatement;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
            scope?: string;
        } = {},
    ): Observable<GridData<ChatMessage>>
    {
        // get result, map ang throw data across observable
        return this.graphqlService
            .client()
            .watchQuery<{ pagination: GridData<ChatMessage>; }>({
                query    : graphqlStatement,
                variables: {
                    query,
                    constraint,
                },
                context: {
                    headers,
                },
            })
            .valueChanges
            .pipe(
                first(),
                map(result => result.data.pagination),
                tap(pagination => scope ? this.setScopePagination(scope, pagination) : this.paginationSubject$.next(pagination)),
            );
    }

    findById(
        {
            graphqlStatement = findByIdQuery,
            id = null,
            constraint = {},
            headers = {},
            scope,
        }: {
            graphqlStatement?: DocumentNode;
            id?: string;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
            scope?: string;
        } = {},
    ): Observable<{
        object: ChatMessage;
    }>
    {
        return this.graphqlService
            .client()
            .watchQuery<{
                object: ChatMessage;
            }>({
                query    : parseGqlFields(graphqlStatement, fields, constraint),
                variables: {
                    id,
                    constraint,
                },
                context: {
                    headers,
                },
            })
            .valueChanges
            .pipe(
                first(),
                map(result => result.data),
                tap(data => scope ? this.setScopeMessage(scope, data.object) : this.messageSubject$.next(data.object)),
            );
    }

    find(
        {
            graphqlStatement = findQuery,
            query = {},
            constraint = {},
            headers = {},
            scope,
        }: {
            graphqlStatement?: DocumentNode;
            query?: QueryStatement;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
            scope?: string;
        } = {},
    ): Observable<{
        object: ChatMessage;
    }>
    {
        return this.graphqlService
            .client()
            .watchQuery<{
                object: ChatMessage;
            }>({
                query    : parseGqlFields(graphqlStatement, fields, query, constraint),
                variables: {
                    query,
                    constraint,
                },
                context: {
                    headers,
                },
            })
            .valueChanges
            .pipe(
                first(),
                map(result => result.data),
                tap(data => scope ? this.setScopeMessage(scope, data.object) : this.messageSubject$.next(data.object)),
            );
    }

    get(
        {
            graphqlStatement = getQuery,
            query = {},
            constraint = {},
            headers = {},
            scope,
        }: {
            graphqlStatement?: DocumentNode;
            query?: QueryStatement;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
            scope?: string;
        } = {},
    ): Observable<{
        objects: ChatMessage[];
    }>
    {
        return this.graphqlService
            .client()
            .watchQuery<{
                objects: ChatMessage[];
            }>({
                query    : parseGqlFields(graphqlStatement, fields, query, constraint),
                variables: {
                    query,
                    constraint,
                },
                context: {
                    headers,
                },
            })
            .valueChanges
            .pipe(
                first(),
                map(result => result.data),
                tap(data => scope ? this.setScopeMessages(scope, data.objects) : this.messagesSubject$.next(data.objects)),
            );
    }

    create<T>(
        {
            graphqlStatement = createMutation,
            object = null,
            headers = {},
        }: {
            graphqlStatement?: DocumentNode;
            object?: ChatCreateMessage;
            headers?: GraphQLHeaders;
        } = {},
    ): Observable<FetchResult<T>>
    {
        return this.graphqlService
            .client()
            .mutate({
                mutation : graphqlStatement,
                variables: {
                    payload: object,
                },
                context: {
                    headers,
                },
            });
    }

    insert<T>(
        {
            graphqlStatement = insertMutation,
            objects = null,
            headers = {},
        }: {
            graphqlStatement?: DocumentNode;
            objects?: ChatCreateMessage[];
            headers?: GraphQLHeaders;
        } = {},
    ): Observable<FetchResult<T>>
    {
        return this.graphqlService
            .client()
            .mutate({
                mutation : graphqlStatement,
                variables: {
                    payload: objects,
                },
                context: {
                    headers,
                },
            });
    }

    updateById<T>(
        {
            graphqlStatement = updateByIdMutation,
            object = null,
            headers = {},
        }: {
            graphqlStatement?: DocumentNode;
            object?: ChatUpdateMessageById;
            headers?: GraphQLHeaders;
        } = {},
    ): Observable<FetchResult<T>>
    {
        return this.graphqlService
            .client()
            .mutate({
                mutation : graphqlStatement,
                variables: {
                    payload: object,
                },
                context: {
                    headers,
                },
            });
    }

    update<T>(
        {
            graphqlStatement = updateMutation,
            object = null,
            query = {},
            constraint = {},
            headers = {},
        }: {
            graphqlStatement?: DocumentNode;
            object?: ChatUpdateMessages;
            query?: QueryStatement;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
        } = {},
    ): Observable<FetchResult<T>>
    {
        return this.graphqlService
            .client()
            .mutate({
                mutation : graphqlStatement,
                variables: {
                    payload: object,
                    query,
                    constraint,
                },
                context: {
                    headers,
                },
            });
    }

    deleteById<T>(
        {
            graphqlStatement = deleteByIdMutation,
            id = null,
            constraint = {},
            headers = {},
        }: {
            graphqlStatement?: DocumentNode;
            id?: string;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
        } = {},
    ): Observable<FetchResult<T>>
    {
        return this.graphqlService
            .client()
            .mutate({
                mutation : graphqlStatement,
                variables: {
                    id,
                    constraint,
                },
                context: {
                    headers,
                },
            });
    }

    delete<T>(
        {
            graphqlStatement = deleteMutation,
            query = {},
            constraint = {},
            headers = {},
        }: {
            graphqlStatement?: DocumentNode;
            query?: QueryStatement;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
        } = {},
    ): Observable<FetchResult<T>>
    {
        return this.graphqlService
            .client()
            .mutate({
                mutation : graphqlStatement,
                variables: {
                    query,
                    constraint,
                },
                context: {
                    headers,
                },
            });
    }

    // Queries additionalApis
    createConversationAndPaginate(
        {
            graphqlStatement = createConversationAndPaginateMessageQuery,
            groupCode = null,
            conversationId = null,
            conversationAccountIds = null,
            conversationSubject = null,
            objectId = null,
            query = {},
            constraint = {},
            headers = {},
            scope,
        }: {
            graphqlStatement?: DocumentNode;
            groupCode?: string;
            conversationId?: string;
            conversationAccountIds?: string[];
            conversationSubject?: string;
            objectId?: string;
            query?: QueryStatement;
            constraint?: QueryStatement;
            headers?: GraphQLHeaders;
            scope?: string;
        } = {},
    ): Observable<GridData<ChatMessage>>
    {
        return this.graphqlService
            .client()
            .watchQuery<{
                pagination: GridData<ChatMessage>;
            }>({
                query    : graphqlStatement,
                variables: {
                    groupCode,
                    conversationId,
                    conversationAccountIds,
                    conversationSubject,
                    objectId,
                    query,
                    constraint,
                },
                context: {
                    headers,
                },
            })
            .valueChanges
            .pipe(
                first(),
                map(result => result.data.pagination),
                tap(pagination => scope ? this.setScopePagination(scope, pagination) : this.paginationSubject$.next(pagination)),
            );
    }
}
