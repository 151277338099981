import gql from 'graphql-tag';

export const fields = `
    groupId
    group {
        id
        name
        code
        description
    }
    objectId
    conversationId
    conversation {
        id
        objectId
        accountIds
        subject
        status
    }
    accountId
    account {
        id
        type
        code
        email
        username
        isActive
        tags
        scopes
        dApplicationCodes
        dPermissions
        dTenants
        meta
    }
    status
    message
    createdAt
    updatedAt
`;

export const relationsFields = `
`;

// default methods
export const paginationQuery = gql`
    query ChatPaginateMessages (
        $query: QueryStatement
        $constraint: QueryStatement
    ) {
        pagination: chatPaginateMessages (
            query: $query
            constraint: $constraint
        ) {
            total
            rows
            count
        }
    }
`;

export const getQuery = gql`
    query ChatGetMessages (
        $query: QueryStatement
        $constraint: QueryStatement
    ) {
        objects: chatGetMessages (
            query: $query
            constraint: $constraint
        ) {
            id
            #FIELDS
        }
    }
`;

export const findByIdQuery = gql`
    query ChatFindMessageById (
        $id: ID
        $constraint: QueryStatement
    ) {
        object: chatFindMessageById (
            id: $id
            constraint: $constraint
        ) {
            id
            #FIELDS
        }
    }
`;

export const findQuery = gql`
    query ChatFindMessage (
        $query: QueryStatement
        $constraint: QueryStatement
    ) {
        object: chatFindMessage (
            query: $query
            constraint: $constraint
        ) {
            id
            #FIELDS
        }
    }
`;

export const createMutation = gql`
    mutation ChatCreateMessage (
        $payload: ChatCreateMessageInput!
    ) {
        chatCreateMessage (
            payload: $payload
        ) {
            ${fields}
        }
    }
`;

export const insertMutation = gql`
    mutation ChatCreateMessages (
        $payload: [ChatCreateMessageInput]!
    ) {
        chatCreateMessages (
            payload: $payload
        )
    }
`;

export const updateByIdMutation = gql`
    mutation ChatUpdateMessageById (
        $payload: ChatUpdateMessageByIdInput!
        $constraint: QueryStatement
    ) {
        chatUpdateMessageById (
            payload: $payload
            constraint: $constraint
        ) {
            ${fields}
        }
    }
`;

export const updateMutation = gql`
    mutation ChatUpdateMessages (
        $payload: ChatUpdateMessagesInput!
        $query: QueryStatement
        $constraint: QueryStatement
    ) {
        chatUpdateMessages (
            payload: $payload
            query: $query
            constraint: $constraint
        ) {
            ${fields}
        }
    }
`;

export const deleteByIdMutation = gql`
    mutation ChatDeleteMessageById (
        $id: ID!
        $constraint: QueryStatement
    ) {
        chatDeleteMessageById (
            id: $id
            constraint: $constraint
        ) {
            ${fields}
        }
    }
`;

export const deleteMutation = gql`
    mutation ChatDeleteMessages (
        $query: QueryStatement
        $constraint: QueryStatement
    ) {
        chatDeleteMessages (
            query: $query
            constraint: $constraint
        ) {
            ${fields}
        }
    }
`;

// Queries additionalApis
export const createConversationAndPaginateMessageQuery = gql`
    query ChatCreateConversationAndPaginateMessage (
        $groupCode: GraphQLString!
        $conversationId: GraphQLString
        $conversationAccountIds: [ID]
        $conversationSubject: GraphQLString
        $objectId: GraphQLString
        $query: QueryStatement
        $constraint: QueryStatement
    ) {
        chatCreateAndPaginateMessage (
            groupCode: $groupCode
            conversationId: $conversationId
            conversationAccountIds: $conversationAccountIds
            conversationSubject: $conversationSubject
            objectId: $objectId
            query: $query
            constraint: $constraint
        ) {
            total
            rows
            count
        }
    }
`;
